import { useAuth, useCompanies } from '@api'
import { Loader } from '@components'
import Button from '@components/buttons/Button'
import Layout from '@components/Layout'
import CompaniesTable from '@components/tables/CompaniesTable'
import PlusIcon from '@public/icons/plus.svg'
import type { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'

export const ACQUIRING_PROVIDERS = ['none', 'mono'] as const
export const FISCALIZATION_PROVIDERS = ['none', 'checkbox'] as const
export const RECALCULATION_STATUSES = [
  'PARTIAL_RETURN',
  'NOT_DELIVERED',
] as const

const HomePage = () => {
  const { t } = useTranslation()

  const router = useRouter()

  const companiesQuery = useCompanies()
  const authQuery = useAuth()

  if (!companiesQuery.isSuccess || !authQuery.isSuccess) {
    return <Loader queries={[companiesQuery, authQuery]} />
  }

  const onClickRow = (companyId: string) => {
    localStorage.setItem('companyId', companyId)
    router.push(`/companies/${companyId}/orders`)
  }

  return (
    <Layout
      title={t('pages.home.title')}
      headerChildren={
        <Button
          onClick={() => router.push('companies/create')}
          label={t('pages.home.create')}
          icon={<PlusIcon className="w-[22px] h-[22px]" />}
        />
      }
    >
      <CompaniesTable
        data={companiesQuery.data.companies}
        onClickRow={onClickRow}
        onClickEdit={companyId => router.push(`companies/${companyId}/edit`)}
      />
    </Layout>
  )
}

export default HomePage

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!)),
    },
  }
}
