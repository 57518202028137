import LoadingIcon from '@public/icons/loading.svg'

const Loading = () => {
  return (
    <div className="grid min-h-[140px] h-full w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
      <LoadingIcon className="w-16 h-16 animate-spin text-primary/50" />
    </div>
  )
}

export default Loading
